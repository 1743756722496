import { mapMutations, mapState } from 'vuex';

export default {
  async fetch() {
    const { data: prices } = await this.$bolev.prices();
    this.setPrices(prices);
    const { data } = await this.$wp.options();
    this.setOptions(data);
    if (this.inShop) {
      const [categories, tags] = await Promise.all([
        this.$wc.categories(),
        this.$wc.tags(),
      ]);
      this.setTags(tags.data);
      this.setCategories(categories.data);
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      token: (state) => state.token,
      menu: (state) => state.menu,
      clinics: (state) => state.clinics,
      closestClinicId: (state) => state.closestClinic,
      shopInfo: (state) => state.options.field_shop_start,
      inShop: (state) => state.inShop,
      mbwayPending: (state) => state.mbwayPending,
    }),
  },

  watch: {
    user(value) {
      if (!value) {
        this.clearOrder();
      }
    },
  },

  mounted() {
    window.dataLayer = window.dataLayer || [];
    this.gtag('consent', 'default', {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 500,
    });
    this.gtag('set', 'ads_data_redaction', true);
    this.gtag('set', 'url_passthrough', true);
    this.$gtm.init(process.env.VUE_APP_GTAG);
    if (this.token) {
      this.getLoggedInUser();
    }
    if (this.closestClinicId === null) {
      this.findClosestClinic();
    }
  },

  methods: {
    ...mapMutations([
      'setPrices',
      'setUser',
      'setToken',
      'setCategories',
      'setTags',
      'setPatientId',
      'setBlogCategories',
      'setDeliveryAddress',
      'clearOrder',
      'setOptions',
      'setClosestClinic',
      'setPostTags',
    ]),

    gtag() {
      window.dataLayer.push(arguments);
    },

    findClosestClinic() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.positionSuccess,
          this.positionError
        );
      }
    },

    positionError() {},

    positionSuccess(pos) {
      const closestClinic = this.$geo.closestClinic(pos.coords, this.clinics);
      this.setClosestClinic(closestClinic.id);
    },

    getLoggedInUser() {
      this.$bolev
        .user()
        .then((res) => {
          if (res.data) {
            this.setUser(res.data);
            this.setPatientId(res.data.patientId);
            this.setDeliveryAddress(res.data.deliveryAddress);
          }
        })
        .catch(() => {
          this.setToken(null);
          this.setPatientId(null);
          this.setUser(null);
        });
    },
  },

  head() {
    return this.$seo.page('site', this.$route.path);
  },
};
