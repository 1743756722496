/* eslint-disable prettier/prettier */
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['setUtmParams']),
  },

  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const utmParams = Object.keys(params)
      .filter((k) => k.startsWith('utm_'))
      .reduce((previous, current) => {
        previous[current.replace(/utm_/, '')] = params[current];
        return previous;
      }, {});
    if (Object.keys(utmParams).length) {
      this.setUtmParams(utmParams);
    }
  },

  computed: {
    isPortuguese() {
      return process.env.LANG === 'pt';
    },
    isSpanish() {
      return process.env.LANG === 'es';
    },
    isFrench() {
      return process.env.LANG === 'fr';
    },
  },
};
