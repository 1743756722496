const getIp = async () => {
  const res = await fetch('https://ipinfo.io/json');
  const json = await res.json();
  return json.ip;
};
const getCookie = (name) => {
  const c = document.cookie
    .split(';')
    .map((e) => e.split('=').map((e) => e.trim()))
    .find((e) => e[0] === name);
  return c ? c[1] : null;
};
const isMetaPixelInstalled = () =>
  Boolean(window.fbq && window.fbq.getState().pixels.length);
const isTrackingGranted = () => {
  const consent = window.dataLayer.find(
    (e) => e[0] === 'consent' && e[1] === 'update'
  );
  return Boolean(
    consent &&
      consent.length > 2 &&
      consent[2].ad_personalization === 'granted' &&
      consent[2].analytics_storage === 'granted'
  );
};

export default ({ app, store, $axios }, inject) => {
  const apiCall = ({ endpoint, method, payload, guest, root }) => {
    const namespace = root ? '' : '/api';
    method = method || 'get';
    const token = guest ? process.env.BOLEV_APP_TOKEN : store.state.token;
    return $axios({
      method,
      url: `${process.env.BOLEV_URL}${namespace}/${endpoint}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
  };

  inject('bolev', {
    login: ({ password, email }) =>
      apiCall({
        endpoint: 'oauth/token',
        method: 'post',
        root: true,
        payload: {
          grant_type: 'password',
          client_id: process.env.BOLEV_CLIENT_ID,
          client_secret: process.env.BOLEV_CLIENT_SECRET,
          username: email,
          password,
          scope: '',
        },
      }),

    user: () => apiCall({ endpoint: 'user' }),

    requestResetPassword: (payload) =>
      apiCall({
        endpoint: 'password/forgot',
        method: 'post',
        payload,
      }),

    resetPassword: (payload) =>
      apiCall({
        endpoint: 'password/reset',
        method: 'post',
        payload,
      }),

    createUser: (payload) =>
      apiCall({
        endpoint: 'createCustomer',
        method: 'post',
        payload,
      }),

    getShipping: (payload) =>
      apiCall({
        endpoint: payload.patientId ? 'shipping' : 'shipping2',
        method: 'post',
        payload,
        guest: !payload.patientId,
      }),

    createOrder: (payload) =>
      apiCall({
        endpoint: payload.patientId ? 'order' : 'order2',
        method: 'post',
        payload,
        guest: !payload.patientId,
      }),

    changeDelivery: (payload) =>
      apiCall({
        endpoint: 'delivery',
        method: 'put',
        payload,
      }),

    changeAddress: (payload) =>
      apiCall({
        endpoint: 'customer',
        method: 'put',
        payload,
      }),

    updateOrder: (payload) =>
      apiCall({
        endpoint: payload.patientId ? 'order' : 'order2',
        method: 'put',
        payload,
        guest: !payload.patientId,
      }),

    getOrder: (payload) =>
      apiCall({
        endpoint: payload.patientId
          ? `order/${payload.orderId}`
          : `order2/${payload.orderId}`,
        method: 'get',
        payload,
        guest: !payload.patientId,
      }),

    getOrderByHash: (payload) =>
      apiCall({
        endpoint: `orderByHash/${payload.hash}`,
        method: 'get',
        payload,
      }),

    checkCustomer: (payload) =>
      apiCall({
        endpoint: payload.patientId ? 'customer' : 'customer2',
        method: 'post',
        payload,
        guest: !payload.patientId,
      }),

    coupon: (payload) =>
      apiCall({
        endpoint: payload.patientId ? 'coupon' : 'coupon2',
        payload,
        method: 'post',
        guest: !payload.patientId,
      }),

    deleteCoupon: (payload) =>
      apiCall({
        endpoint: 'coupon',
        payload,
        method: 'delete',
      }),

    prices: () => apiCall({ endpoint: 'prices2', guest: true }),

    notifyWhenBackInStock: (payload) =>
      apiCall({
        endpoint: payload.patientId ? 'stock' : 'stock2',
        payload,
        method: 'post',
        guest: !payload.patientId,
      }),

    getFavorites: (payload) =>
      apiCall({
        endpoint: `patient/${payload.patientId}/favorites`,
        method: 'get',
        payload,
      }),

    addToFavorites: (payload) =>
      apiCall({ endpoint: 'favorites', method: 'post', payload }),

    removeFromFavorites: (payload) =>
      apiCall({ endpoint: 'favorites', method: 'delete', payload }),

    levPoints: (payload) =>
      apiCall({ endpoint: 'levpoints', method: 'post', payload }),

    pointsOrder: (payload) =>
      apiCall({ endpoint: 'pointsOrder', method: 'post', payload }),

    createLead: (payload) =>
      apiCall({
        endpoint: 'lead',
        method: 'post',
        guest: 'true',
        payload,
      }),

    getReviewProducts: ({ orderId, hash }) =>
      apiCall({
        endpoint: `orderProducts/${orderId}/${hash}`,
        guest: true,
      }),

    getProductStock: ({ sku, patientId }) => {
      return apiCall({
        endpoint: patientId ? `quantity/${sku}` : `quantity2/${sku}`,
        method: 'get',
        guest: !patientId,
      });
    },

    hiPay: (payload) =>
      apiCall({
        endpoint: 'hipay',
        method: 'post',
        payload,
      }),

    hiPayCheckStatus: (payload) =>
      apiCall({
        endpoint: 'hipay/poll',
        method: 'post',
        payload,
      }),

    metaEvent: async (payload) => {
      if (!isMetaPixelInstalled()) return;
      if (!isTrackingGranted()) return;
      return apiCall({
        endpoint: payload.patientId ? 'meta' : 'meta2',
        method: 'post',
        guest: !payload.patientId,
        payload: {
          ...payload,
          ip: await getIp(),
          userAgent: window.navigator.userAgent,
          url: window.location.href,
          fbc: getCookie('_fbc'),
          fbp: getCookie('_fbp'),
        },
      });
    },

    /* 
      meta payload
      {
        type: 'pageview',
        sku: 'XXX112',
        quntity: 1,
        price: 1000,
      }

      events:
        'purchase',
        'contentview',
        'addToCart',
        'checkout',
        'paymentInitiated',
        'pageView',
      */
  });
};

/**
 * https://laravel.com/docs/8.x/passport#client-credentials-grant-tokens
 * expires in 1 year
 * dont know if Passport::personalAccessTokensExpireIn (AuthServiceProvder) refreshes it
 *
 *
 * curl --location --request POST 'https://bolev.dev.afonso.se/oauth/token' \
 *  --header 'Content-Type: application/json' \
 *  --data-raw '{
 *      "grant_type" : "client_credentials",
 *       "client_id" : 3,
 *       "client_secret" : "SAQDnC53n2Y4Pex1JWcsZ0Xdi1niYoSBIL6P9hFP",
 *       "scope" : ""
 *   }'
 *
 */
