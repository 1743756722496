
export default {
  name: 'ConsentCheckbox',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      internValue: false,
    };
  },

  computed: {
    consentText() {
      const link = this.isPortuguese
        ? '/politica-de-privacidade'
        : this.isSpanish
        ? '/politica-de-privacidad'
        : '';
      const privacyPolicyLink = `<a href="${link}" target="_blank">${this.$t(
        'forms.privacyPolicy'
      )}</a>`;
      return this.$t('forms.consent').replace('{link}', privacyPolicyLink);
    },
  },

  watch: {
    value(value) {
      this.internValue = value;
    },
  },

  mounted() {
    this.internValue = this.value;
  },
};
